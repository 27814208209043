<template>
  <v-layout fluid fill-height>
    <div
      class="bmc"
      :class="{ presentation: showAsPresentation, print: showAsPrint }"
    >
      <v-progress-linear
        v-if="showAsPresentation && !showAsPrint"
        v-model="presentationProgress"
        height="4"
        class="ma-0"
      ></v-progress-linear>
      <div class="credits-own caption">
        Generated by BM|Desginer
        <a href="https://bmdesigner.com" target="_blank">(bmdesigner.com)</a>
      </div>
      <div class="credits caption">
        <a
          href="https://strategyzer.com/canvas/business-model-canvas"
          target="_blank"
          >The Business Model Canvas</a
        >
        by
        <a href="http://strategyzer.com" target="_blank">Strategyzer AG</a> is
        licensed under
        <a href="http://creativecommons.org/licenses/by-sa/3.0" target="_blank"
          >CC BY-SA 3.0</a
        >
      </div>
      <image-zone
        :allow-click="false"
        @image-drop="addNote"
        class="canvas"
        @click.native.prevent.stop="addNote($event)"
      >
        <div
          ref="paper"
          class="paper elevation-10"
          :class="{ game: canvas && canvas.info && canvas.info.isGame }"
          data-none="bmc_tmp"
        >
          <draw-surface
            v-if="
              $store.state.layout.showDrawSurface &&
              !$store.state.layout.showVPC
            "
          ></draw-surface>
          <v-progress-linear
            transition="slide-y-transition"
            class="ma-0"
            v-if="isLoading"
            indeterminate
            style="z-index: 1"
          ></v-progress-linear>

          <zone
            dropzone-accept=".note-bmc"
            id="c"
            label="Cost Structure"
            style="left: 0; top: 75%; width: 40%; height: 25%"
          >
            <v-icon light slot="icon">{{ ICONS["c"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="pn"
            label="Partner Network"
            style="left: 0; top: 0; width: 20%; height: 75%"
          >
            <v-icon light slot="icon">{{ ICONS["pn"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="ka"
            label="Key Activities"
            style="left: 20%; top: 0; width: 20%; height: 37.5%"
          >
            <v-icon light slot="icon">{{ ICONS["ka"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="kr"
            label="Key Resources"
            style="left: 20%; top: 37.5%; width: 20%; height: 37.5%"
          >
            <v-icon light slot="icon">{{ ICONS["kr"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="vp"
            class="zone-highlight"
            :class="{
              'highlight-on': selectedCS && !selectedVP,
              'elevation-10': selectedCS && !selectedVP
            }"
            label="Value Proposition"
            style="left: 40%; top: 0; width: 20%; height: 75%"
          >
            <v-icon light slot="icon">{{ ICONS["vp"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="cr"
            label="Customer Relationships"
            style="left: 60%; top: 0; width: 20%; height: 37.5%"
          >
            <v-icon light slot="icon">{{ ICONS["cr"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="dc"
            label="Distribution Channels"
            style="left: 60%; top: 37.5%; width: 20%; height: 37.5%"
          >
            <v-icon light slot="icon">{{ ICONS["dc"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="cs"
            class="zone-highlight"
            :class="{
              'highlight-on': !selectedCS && selectedVP,
              'elevation-10': !selectedCS && selectedVP
            }"
            label="Customer Segments"
            style="left: 80%; top: 0; width: 20%; height: 75%"
          >
            <v-icon light slot="icon">{{ ICONS["cs"] }}</v-icon>
          </zone>
          <zone
            dropzone-accept=".note-bmc"
            id="r"
            label="Revenue Streams"
            style="left: 60%; top: 75%; width: 40%; height: 25%"
          >
            <v-icon light slot="icon">{{ ICONS["r"] }}</v-icon>
          </zone>
          <div
            v-if="canvas && canvas.info"
            class="logo"
            light
            :style="{ 'background-color': canvas.info.logoColor }"
          >
            <image-zone
              :allow-click="$store.state.layout.isEditable"
              :image="canvas.info.logoImage"
              @update:image="canvasInfoUpdate({ logoImage: $event })"
              :color="canvas.logoColor"
              @update:color="canvasInfoUpdate({ logoColor: $event })"
            ></image-zone>
          </div>
          <div>
            <note
              v-for="note in notesBMC"
              :value="note"
              :key="note.id"
              class="note-bmc"
              :class="{
                'highlight-on':
                  (selectedCS && !selectedVP && note.type === 'vp') ||
                  (!selectedCS && selectedVP && note.type === 'cs')
              }"
              :parent="$refs.paper"
            ></note>
          </div>
        </div>
      </image-zone>
      <vpc></vpc>
      <game-status-bar
        v-if="canvas && canvas.info && canvas.info.isGame"
        @won="showCongrats = true"
      ></game-status-bar>
      <pop-in-text v-if="showCongrats" v-model="showCongrats"></pop-in-text>
      <presentation-controls
        v-if="showAsPresentation && !showAsPrint"
      ></presentation-controls>
    </div>
  </v-layout>
</template>

<script>
import debounce from "lodash.debounce";
import Note from "@/components/Note";
import Zone from "@/components/Zone";
import Vpc from "@/components/VPC";
import PopInText from "@/components/PopInText";
import GameStatusBar from "@/components/GameStatusBar";
import DrawSurface from "@/components/DrawSurface";
import ImageZone from "@/components/ImageZone";
import PresentationControls from "@/components/PresentationControls";
import { mapGetters, mapState, mapActions } from "vuex";
import { totalOffset, ICONS } from "@/utils";
import { db } from "@/utils/firebase";
import * as types from "@/store/mutation-types";

let resizeHandler;

export default {
  name: "bmc",
  data() {
    return {
      isLoading: false,
      showAsPresentation: false,
      showAsPrint: false,
      ICONS,
      showCongrats: false
    };
  },
  mounted() {
    if (this.$route.name === "bmc") {
      this.fetchData();
    } else if (this.$route.name === "print") {
      // fetch data from sessionsid
      this.fetchPrintData(this.$route.params.id).then(() => {
        this.showAsPresentation = true;
        this.showAsPrint = true;
        this.handleWindowResize();
        window.dispatchEvent(new Event("resize"));
      });
    }
  },
  beforeDestroy() {
    if (resizeHandler) {
      window.removeEventListener("resize", resizeHandler);
    }
    this.$store.dispatch("unbindCanvas");
  },
  computed: {
    ...mapGetters(["notesBMC", "canvasSettings"]),
    ...mapState({
      selectedVP: state => state.layout.selectedVP,
      selectedCS: state => state.layout.selectedCS,
      canvas: state => state.canvas
    }),
    presentationProgress() {
      if (!this.canvas || !this.canvas.notesPresentationOrder) {
        return 0;
      }
      return (
        ((this.canvas.notesPresentationOrder.indexOf(
          this.canvas.currentPresentationKey
        ) +
          1) *
          100) /
        this.canvas.notesPresentationOrder.length
      );
    },
    listMode() {
      return this.canvasSettings.listMode;
    }
  },
  watch: {
    listMode() {
      // triggers heigh calculations
      window.dispatchEvent(new Event("resize"));
    },
    // call again the method if the route changes
    $route: "fetchData",
    "$store.state.layout.presentation": function animatePresentation(val) {
      const crowdShortcut = document.getElementById("crowd-shortcut");
      if (val) {
        const offset = totalOffset(this.$el);
        this.$el.style.top = `${offset.top}px`;
        this.$el.style.left = `${offset.left}px`;
        this.$el.style.position = "absolute";
        if (crowdShortcut) {
          crowdShortcut.style.display = "none";
        }
        setTimeout(() => {
          this.showAsPresentation = true;
        }, 0);
      } else {
        this.showAsPresentation = false;
        setTimeout(() => {
          this.$el.style.top = "";
          this.$el.style.left = "";
          this.$el.style.position = "relative";
          if (crowdShortcut) {
            crowdShortcut.style.display = "block";
          }
        }, 500);
      }
    }
  },
  methods: {
    ...mapActions([
      "setCanvasRef",
      "canvasInfoUpdate",
      "zoomNoteKey",
      "fetchPrintData"
    ]),
    fetchData() {
      this.isLoading = true;
      this.setCanvasRef(db.child("projects").child(this.$route.params.id)).then(
        () => {
          this.isLoading = false;
          const zoomed =
            this.zoomNoteKey(this.$route.params.zoom1) ||
            this.zoomNoteKey(this.$route.params.zoom2);
          if (!zoomed) {
            this.$store.commit(types.LAYOUT_UPDATE, { showVPC: false });
          }
        }
      );
      if (resizeHandler) {
        window.removeEventListener("resize", resizeHandler);
      }
      resizeHandler = debounce(this.handleWindowResize, 300);
      window.addEventListener("resize", resizeHandler);
      this.handleWindowResize();
    },
    handleWindowResize() {
      if (!this.$refs.paper) {
        return;
      }
      this.$el.style.setProperty(
        "--zoneLabelFontSize",
        `${this.$refs.paper.offsetHeight * 0.02}px`
      );
      this.$el.style.setProperty(
        "--zoneLabelIconFontSize",
        `${this.$refs.paper.offsetHeight * 0.03}px`
      );
    },
    addNote(e) {
      const offset = totalOffset(this.$refs.paper);
      const noteCenter = {
        x: this.$refs.paper.offsetWidth / 15,
        y: 20
      };
      const x = e.x - noteCenter.x - offset.left;
      const y = e.y - noteCenter.y - offset.top;

      const note = {
        left: x / (this.$refs.paper.offsetWidth / 100),
        top: y / (this.$refs.paper.offsetHeight / 100),
        listLeft: x / (this.$refs.paper.offsetWidth / 100),
        listTop: y / (this.$refs.paper.offsetHeight / 100),
        type: "bmc_tmp",
        colors: this.$store.getters.canvasSettings.lastUsedColors,
        image: e.image
      };

      if (e.target.classList.contains("zone")) {
        note.type = e.target.getAttribute("id");
      }
      // TODO #56: keep previous setting?
      if (e.image) {
        note.showAsSticky = false;
      }

      this.$store.dispatch("NOTE_CREATE", note);
    },
    // TODO: move and generalize for VPC + move to server
    prepareGame() {
      // https://bost.ocks.org/mike/shuffle/
      function shuffle(array) {
        let m = array.length;
        let t;
        let i;

        // While there remain elements to shuffle…
        while (m) {
          // Pick a remaining element…
          m -= 1;
          i = Math.floor(Math.random() * m);

          // And swap it with the current element.
          t = array[m];
          array[m] = array[i];
          array[i] = t;
        }

        return array;
      }
      let it = 0;
      let il = 0;
      const TOP_END = 8;
      const BOTTOM_START = 19;
      const X_INTERVAL = 14;
      const Y_INTERVAL = 18;
      shuffle(this.notesBMC.slice()).forEach((note, i) => {
        if (i > TOP_END && i < BOTTOM_START) {
          if (i % 2 === 1) {
            il = 0;
            it += Y_INTERVAL;
          } else {
            il = 100 + X_INTERVAL;
          }
        }
        if (i === BOTTOM_START) {
          il = 0;
          it = 100 + Y_INTERVAL;
        }
        const left = -X_INTERVAL + Math.random() * 4 + il;
        const top = -Y_INTERVAL + Math.random() * 4 + it;
        il += X_INTERVAL;

        note.left = left;
        note.top = top;
        note.isGame = true;
        note.type_saved = note.type;
        note.type = "bmc_tmp";
        // TODO: source ref if serverside check
      });
      const canvas = Object.assign({}, this.canvas);
      canvas.info = Object.assign({}, this.canvas?.info);
      canvas.info.name += " GAME";
      canvas.info.isGame = true;
      canvas.source = "bmdesigner";

      function downloadObjectAsJson(exportObj, exportName) {
        const dataStr = `data:text/json;charset=utf-8, ${encodeURIComponent(
          JSON.stringify(exportObj)
        )}`;
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", `${exportName}.json`);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }
      downloadObjectAsJson(canvas, canvas.info?.name);
    }
  },
  components: {
    Note,
    Zone,
    Vpc,
    ImageZone,
    DrawSurface,
    GameStatusBar,
    PopInText,
    PresentationControls
  }
};
</script>

<style scoped>
.bmc {
  flex: 1;
  position: relative;
  transition: all 0.5s ease;
  right: 0;
  bottom: 0;
}

.bmc.presentation {
  position: fixed;
  top: 60px !important;
  left: 0 !important;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: #424242;
}

.bmc.print .credits-own,
.bmc.print .credits {
  z-index: 99;
  display: block;
}

.bmc.print .paper {
  width: 100vw;
  max-width: 100vw;
  height: 56.25vw;
  max-height: 100vh;
}

.canvas {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}

.paper {
  width: 80vw;
  height: 54.88vw;
  /* height:width ratio = 9/16 = .5625  */
  max-height: calc(100vh - 100px);
  max-width: calc(145.7vh - 100px);
  /* 16/9 = 1.778 */
  min-width: 1024px;
  min-height: 560px;
  margin: auto;
  position: relative;
}

.paper.game {
  width: 60vw;
  height: 34.88vw;
  min-width: 800px;
  min-height: 440px;
  transition: all 0.5s linear;
}

.zone-highlight {
  z-index: 0;
  transition: z-index 0.5s step-end !important;
}

.highlight-on {
  z-index: 1;
  transition: z-index 0.5s step-start !important;
}

.logo {
  position: absolute;
  top: 75%;
  left: 40%;
  width: 20%;
  height: 25%;
  box-shadow: inset 0 0 0px 1px #818181;
  background-color: #fff;
  color: #333;
}

.logo > div {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.credits {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.credits-own {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: none;
}

.credits-own a,
.credits a {
  text-decoration: none;
}

.bmc.presentation .credits-own a,
.bmc.presentation .credits a {
  color: #9e9e9e;
}

@media (max-width: 1024px) {
  .highlight-on {
    z-index: 0;
  }
}
</style>
