var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"draggable note",class:{
    'list-mode': _vm.listMode,
    'hide-colors': _vm.hideColors,
    'highlight-note': _vm.highlight,
    dragging: _vm.dragging,
    'no-sticky': !_vm.value.showAsSticky
  },style:({
    'background-color': _vm.colorsBG[_vm.color],
    height: (_vm.height + "%"),
    left: (_vm.left + "%"),
    top: (_vm.top + "%"),
    'box-shadow': _vm.boxShadow,
    opacity: _vm.opacity,
    transform: _vm.transform
  }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"wheel":_vm.handleWheel}},[(_vm.isEdit && _vm.$store.state.layout.isEditable && !_vm.hideColors)?_c('div',{staticClass:"colors"},[_vm._l((_vm.value.colors),function(colorIndex,i){return _c('color-selector',{key:i,style:({ transform: ("rotateZ(" + (-_vm.angle) + "deg)") }),attrs:{"value":colorIndex,"small":i > 0,"canDelete":i > 0,"direction":_vm.direction},on:{"input":function($event){return _vm.setColor(i, $event)}}})}),_c('color-selector',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.colors.length < 6),expression:"value.colors.length < 6"}],style:({ transform: ("rotateZ(" + (-_vm.angle) + "deg)") }),attrs:{"small":"","hide":_vm.value.colors,"direction":_vm.direction},on:{"input":function($event){return _vm.setColor(_vm.value.colors.length, $event)}}})],2):_vm._e(),_c('div',{staticClass:"icons"},[(_vm.listMode)?_c('v-spacer'):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.value.description)?_c('v-btn',{staticClass:"description",attrs:{"text":"","icon":"","color":"primary","small":"","light":""},on:{"mouseover":_vm.moveToTop,"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showNoteOptions()}}},[_c('v-icon',[_vm._v("description")])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.value.description))])]),(!_vm.listMode)?_c('v-spacer'):_vm._e(),(_vm.isEdit)?_c('v-btn',{staticClass:"show-detail",attrs:{"text":"","icon":"","color":"primary","small":"","light":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showNoteOptions()}}},[_c('v-icon',[_vm._v("mode_edit")])],1):_vm._e(),(_vm.value.type === 'vp' || _vm.value.type === 'cs')?_c('v-btn',{staticClass:"zoom",attrs:{"text":"","icon":"","color":"primary","small":"","light":""},on:{"click":function($event){return _vm.zoom()}}},[_c('v-icon',[_vm._v("zoom_in")]),_vm._v(" "+_vm._s(_vm.nbChilds)+" ")],1):_vm._e()],1),_c('div',{staticClass:"text-box",class:{ image: _vm.value.image },style:({ 'background-image': ("url(" + (_vm.value.image) + ")") }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('textarea',{ref:"textarea",staticClass:"text",class:{
        'hide-label': !_vm.value.showLabel || _vm.canvasSettings.hideAllLabels
      },style:({ 'font-size': (_vm.fontSize + "px") }),attrs:{"placeholer":"text"},domProps:{"value":_vm.value.text},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"input":_vm.updateText,"focus":_vm.handleFocus,"keydown":function($event){return _vm.handleKeyDown($event)},"keyup":function($event){return _vm.handleKeyUp($event)}}})]),_c('div',{staticClass:"calcvar-display"},[_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.calcResults[_vm.value.calcId] && _vm.value.calcDisplayB)?_c('div',{staticClass:"calcvar-display-b",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showNoteOptions(true)}}},[_vm._v(" "+_vm._s(_vm._f("humanformat")(_vm.calcResults[_vm.value.calcId][_vm.value.calcDisplayB]))+" ")]):_vm._e()])]}}])},[_c('span',[_vm._v(_vm._s(_vm.value.calcDisplayB))])]),_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.calcResults[_vm.value.calcId] && _vm.value.calcDisplayR)?_c('div',{staticClass:"calcvar-display-r",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showNoteOptions(true)}}},[_vm._v(" "+_vm._s(_vm._f("humanformat")(_vm.calcResults[_vm.value.calcId][_vm.value.calcDisplayR]))+" ")]):_vm._e()])]}}])},[_c('span',[_vm._v(_vm._s(_vm.value.calcDisplayR))])]),_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.calcResults[_vm.value.calcId] && _vm.value.calcDisplayG)?_c('div',{staticClass:"calcvar-display-g",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showNoteOptions(true)}}},[_vm._v(" "+_vm._s(_vm._f("humanformat")(_vm.calcResults[_vm.value.calcId][_vm.value.calcDisplayG]))+" ")]):_vm._e()])]}}])},[_c('span',[_vm._v(_vm._s(_vm.value.calcDisplayG))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }