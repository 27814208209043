var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[(_vm.$store.state.currentUser)?_c('v-btn',{staticClass:"floating-action",attrs:{"color":"primary","fab":""},on:{"click":_vm.createNewCanvas}},[_c('v-icon',[_vm._v("add")])],1):_vm._e(),_c('v-row',{attrs:{"wrap":""}},_vm._l((_vm.projects),function(ref){
var info = ref.info;
var settings = ref.settings; if ( settings === void 0 ) settings = {};
var key = ref.key;
return _c('v-col',{key:key,attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-card',{staticClass:"model"},[_c('v-img',{class:{
            'default-background': !info.logoImage,
            'fix-white': info.logoColor == 'rgb(255, 255, 255)'
          },style:({
            'background-color': info.logoColor
              ? info.logoColor
              : _vm.colorHash(info.name)
          }),attrs:{"contain":!!info.logoImage,"src":info.logoImage
              ? info.logoImage
              : require('@/assets/default_bmc_logo_background.jpg')},on:{"click":function($event){return _vm.$router.push({ name: 'bmc', params: { id: key } })}}},[(info.updatedAt)?_c('div',{staticClass:"left-icons"},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" event_note ")]),_c('timeago',{attrs:{"datetime":info.updatedAt}})],1):_vm._e(),_c('div',{staticClass:"right-icons"},[_c('v-badge',{attrs:{"bottom":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(info.stickyCount || 0))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("note")])],1),_c('v-badge',{attrs:{"bottom":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(info.usersCount || 1))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("account_box")])],1),_c('v-spacer'),(info.public)?_c('v-icon',{attrs:{"dark":"","title":"public"}},[_vm._v("public")]):_vm._e(),(!info.public)?_c('v-icon',{attrs:{"dark":"","title":"private"}},[_vm._v("lock")]):_vm._e()],1),_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"fill-height":"","align-end":""}},[_c('v-col',{staticClass:"headline white--text",attrs:{"cols":"12"}},[_vm._v(_vm._s(info.name))])],1)],1)],1),_c('v-card-actions',{staticClass:"white"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFav(key, !settings.fav)}}},[_c('v-icon',{attrs:{"color":settings.fav ? 'primary' : 'grey'}},[_vm._v("favorite")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","to":{ name: 'bmc', params: { id: key } }}},[_vm._v("Open")])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }