var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fluid":"","fill-height":""}},[_c('div',{staticClass:"bmc",class:{ presentation: _vm.showAsPresentation, print: _vm.showAsPrint }},[(_vm.showAsPresentation && !_vm.showAsPrint)?_c('v-progress-linear',{staticClass:"ma-0",attrs:{"height":"4"},model:{value:(_vm.presentationProgress),callback:function ($$v) {_vm.presentationProgress=$$v},expression:"presentationProgress"}}):_vm._e(),_c('div',{staticClass:"credits-own caption"},[_vm._v(" Generated by BM|Desginer "),_c('a',{attrs:{"href":"https://bmdesigner.com","target":"_blank"}},[_vm._v("(bmdesigner.com)")])]),_c('div',{staticClass:"credits caption"},[_c('a',{attrs:{"href":"https://strategyzer.com/canvas/business-model-canvas","target":"_blank"}},[_vm._v("The Business Model Canvas")]),_vm._v(" by "),_c('a',{attrs:{"href":"http://strategyzer.com","target":"_blank"}},[_vm._v("Strategyzer AG")]),_vm._v(" is licensed under "),_c('a',{attrs:{"href":"http://creativecommons.org/licenses/by-sa/3.0","target":"_blank"}},[_vm._v("CC BY-SA 3.0")])]),_c('image-zone',{staticClass:"canvas",attrs:{"allow-click":false},on:{"image-drop":_vm.addNote},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addNote($event)}}},[_c('div',{ref:"paper",staticClass:"paper elevation-10",class:{ game: _vm.canvas && _vm.canvas.info && _vm.canvas.info.isGame },attrs:{"data-none":"bmc_tmp"}},[(
            _vm.$store.state.layout.showDrawSurface &&
            !_vm.$store.state.layout.showVPC
          )?_c('draw-surface'):_vm._e(),(_vm.isLoading)?_c('v-progress-linear',{staticClass:"ma-0",staticStyle:{"z-index":"1"},attrs:{"transition":"slide-y-transition","indeterminate":""}}):_vm._e(),_c('zone',{staticStyle:{"left":"0","top":"75%","width":"40%","height":"25%"},attrs:{"dropzone-accept":".note-bmc","id":"c","label":"Cost Structure"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["c"]))])],1),_c('zone',{staticStyle:{"left":"0","top":"0","width":"20%","height":"75%"},attrs:{"dropzone-accept":".note-bmc","id":"pn","label":"Partner Network"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["pn"]))])],1),_c('zone',{staticStyle:{"left":"20%","top":"0","width":"20%","height":"37.5%"},attrs:{"dropzone-accept":".note-bmc","id":"ka","label":"Key Activities"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["ka"]))])],1),_c('zone',{staticStyle:{"left":"20%","top":"37.5%","width":"20%","height":"37.5%"},attrs:{"dropzone-accept":".note-bmc","id":"kr","label":"Key Resources"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["kr"]))])],1),_c('zone',{staticClass:"zone-highlight",class:{
            'highlight-on': _vm.selectedCS && !_vm.selectedVP,
            'elevation-10': _vm.selectedCS && !_vm.selectedVP
          },staticStyle:{"left":"40%","top":"0","width":"20%","height":"75%"},attrs:{"dropzone-accept":".note-bmc","id":"vp","label":"Value Proposition"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["vp"]))])],1),_c('zone',{staticStyle:{"left":"60%","top":"0","width":"20%","height":"37.5%"},attrs:{"dropzone-accept":".note-bmc","id":"cr","label":"Customer Relationships"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["cr"]))])],1),_c('zone',{staticStyle:{"left":"60%","top":"37.5%","width":"20%","height":"37.5%"},attrs:{"dropzone-accept":".note-bmc","id":"dc","label":"Distribution Channels"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["dc"]))])],1),_c('zone',{staticClass:"zone-highlight",class:{
            'highlight-on': !_vm.selectedCS && _vm.selectedVP,
            'elevation-10': !_vm.selectedCS && _vm.selectedVP
          },staticStyle:{"left":"80%","top":"0","width":"20%","height":"75%"},attrs:{"dropzone-accept":".note-bmc","id":"cs","label":"Customer Segments"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["cs"]))])],1),_c('zone',{staticStyle:{"left":"60%","top":"75%","width":"40%","height":"25%"},attrs:{"dropzone-accept":".note-bmc","id":"r","label":"Revenue Streams"}},[_c('v-icon',{attrs:{"slot":"icon","light":""},slot:"icon"},[_vm._v(_vm._s(_vm.ICONS["r"]))])],1),(_vm.canvas && _vm.canvas.info)?_c('div',{staticClass:"logo",style:({ 'background-color': _vm.canvas.info.logoColor }),attrs:{"light":""}},[_c('image-zone',{attrs:{"allow-click":_vm.$store.state.layout.isEditable,"image":_vm.canvas.info.logoImage,"color":_vm.canvas.logoColor},on:{"update:image":function($event){return _vm.canvasInfoUpdate({ logoImage: $event })},"update:color":function($event){return _vm.canvasInfoUpdate({ logoColor: $event })}}})],1):_vm._e(),_c('div',_vm._l((_vm.notesBMC),function(note){return _c('note',{key:note.id,staticClass:"note-bmc",class:{
              'highlight-on':
                (_vm.selectedCS && !_vm.selectedVP && note.type === 'vp') ||
                (!_vm.selectedCS && _vm.selectedVP && note.type === 'cs')
            },attrs:{"value":note,"parent":_vm.$refs.paper}})}),1)],1)]),_c('vpc'),(_vm.canvas && _vm.canvas.info && _vm.canvas.info.isGame)?_c('game-status-bar',{on:{"won":function($event){_vm.showCongrats = true}}}):_vm._e(),(_vm.showCongrats)?_c('pop-in-text',{model:{value:(_vm.showCongrats),callback:function ($$v) {_vm.showCongrats=$$v},expression:"showCongrats"}}):_vm._e(),(_vm.showAsPresentation && !_vm.showAsPrint)?_c('presentation-controls'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }