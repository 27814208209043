var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$store.state.layout.searchEnabled &&
    _vm.userSettings.search_key &&
    _vm.userSettings.search_key !== 'INIT' &&
    _vm.searchClient
  )?_c('div',[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"bmdesigner_notes_prod"}},[_c('v-dialog',{attrs:{"scrollable":"","origin":"top right","max-width":"800px","content-class":"search-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("search")])],1)]}}],null,false,1400822692),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column align-start"},[_c('h3',{staticClass:"h3"},[_vm._v("Search")]),_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var currentRefinement = ref.currentRefinement;
  var isSearchStalled = ref.isSearchStalled;
  var refine = ref.refine;
return [_c('v-text-field',{attrs:{"full-width":"","clearable":"","placeholder":"Project or Notes","value":currentRefinement},on:{"input":function($event){return refine($event || '')}}}),_c('span',{attrs:{"hidden":!isSearchStalled}},[_vm._v("Loading...")])]}}],null,false,2598528913)}),_c('div',{staticClass:"filters"},[_c('h6',{staticClass:"subheading"},[_vm._v("Types")]),_c('ais-refinement-list',{staticClass:"type-facet body-1",attrs:{"attribute":"type","sort-by":['count:desc', 'name:asc', 'isRefined:desc'],"transform-items":function (items) { return items.map(function (item) { return (Object.assign({}, item,
                    {label: _vm.TYPE_NAMES[item.value]})); }); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
                  var refine = ref.refine;
return [_c('v-chip',{attrs:{"outlined":!item.isRefined},on:{"click":function($event){return refine(item.value)}}},[_c('v-badge',{attrs:{"inline":"","content":item.count}},[_vm._v(" "+_vm._s(_vm.TYPE_NAMES[item.value])+" ")])],1)]}}],null,false,791812279)}),_c('h6',{staticClass:"subheading"},[_vm._v("Colors")]),_c('ais-refinement-list',{staticClass:"color-facet body-1",attrs:{"attribute":"colors","operator":"and","sort-by":['name:asc', 'count:desc', 'isRefined:desc']},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
                  var refine = ref.refine;
return [_c('v-btn',{staticClass:"color",class:[
                    _vm.COLORS_MATERIAL_DARK[item.value],
                    item.isRefined ? 'active' : ''
                  ],attrs:{"small":"","fab":"","text":item.isRefined},on:{"click":function($event){return refine(item.value)}}},[_vm._v(" "+_vm._s(item.count)+" "),(item.isRefined)?_c('v-icon',[_vm._v("check_circle")]):_vm._e()],1)]}}],null,false,3733594429)})],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-list',{staticClass:"search-result",attrs:{"two-line":"","light":""}},[_c('ais-hits',{scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('v-list-item',{staticClass:"search-result",attrs:{"to":{
                    name: 'bmc',
                    params: {
                      id: item.canvasKey,
                      zoom1: item.objectID.split('.')[1]
                    }
                  },"exact":""},on:{"click":function($event){_vm.showDialog = false}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('ais-highlight',{attrs:{"hit":item,"attribute":"text"}}),_vm._v("  ")],1),_c('v-list-item-subtitle',{staticClass:"grey--text text--darken-4 description"},[_c('ais-highlight',{attrs:{"hit":item,"attribute":"description"}})],1),_c('v-list-item-subtitle',[_c('ais-highlight',{attrs:{"hit":item,"attribute":"projectTitle"}})],1)],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_c('div',{staticClass:"type"},[_vm._v(_vm._s(_vm.TYPE_NAMES[item.type]))]),_c('div',{staticClass:"colors"},_vm._l((item.colors),function(colorId){return _c('span',{key:colorId,class:_vm.COLORS_MATERIAL_DARK[colorId]})}),0)])],1)],1),_c('v-divider')]}}],null,false,2567530028)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }